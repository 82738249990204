import React from 'react';
import { withAuth, withAuthProps } from '../../common/hooks/withAuth';
import { ReduxState } from '../../reducers';
import { connect } from 'react-redux';
import { loadServerPlayers, loadUserServerMap } from '../../actions/social';
import { AlderonFriend, SocialMap } from '../../api/social.types';
import { SelectOption } from '../common/Select';
import { SocialComponent } from './SocialComponent';
import { ServerInfo } from '../../api/servers.types';
import { Box, Button } from '@mui/material';

interface ReduxStateProps {
  map: SocialMap
  friends: SelectOption<AlderonFriend>[]
  servers: { [key: string]: ServerInfo }
}

interface ReduxActionProps {
  loadUserServerMap: (expandServerPlayers?: boolean) => void;
  loadServerPlayers: (serverKey: string)=> void;
}

interface ComponentProps extends withAuthProps {}

interface SocialState {
  map: SocialMap
}

export type SocialProps = ComponentProps & ReduxStateProps & ReduxActionProps & withAuthProps;

class SocialMapComponent extends React.Component<SocialProps, SocialState> {
  state: SocialState = {
    map: {},
  }

  componentDidMount(): void {
    this.updateUserMap(this.props.map)
    this.props.loadUserServerMap()
  }

  updateUserMap = (map: SocialMap) => {
    const keys = Object.keys(map);
    const userServerUuid = keys.find((key) => map[key]?.friends?.find((friend) => friend.id.identifier === this.props.user?.id));
    if (!userServerUuid) {
      return;
    }

    this.setState({
      map: {
        [userServerUuid]: map[userServerUuid],
      },
    })
  }

  componentDidUpdate(prevProps: Readonly<SocialProps>, prevState: Readonly<SocialState>, snapshot?: any): void {
      const { map } = this.props;
      if (map && map !== prevProps.map) {
        this.updateUserMap(map);
      }
  }

  onPullServerPlayers = () => {
    const { servers } = this.props;
    const serverKeys = Object.keys(servers);
    if (serverKeys.length === 0) {
      return;
    }
    const serverKey = servers[serverKeys[0]].id.key
    this.props.loadServerPlayers(serverKey)
  }

  render = () => {
    const { friends, servers } = this.props;
    const { map } = this.state;
    return (
      <SocialComponent
        map={map}
        friends={friends}
        onRefresh={this.props.loadUserServerMap}
        servers={servers}
        onPullServerPlayers={this.onPullServerPlayers}
      />
    )
  }
}


const mapStateToProps = ({ social }: ReduxState) => {
  return {
    map: social.map,
    friends: social.friends,
    servers: social.servers,
  }
};

export const UserServer = withAuth(connect(mapStateToProps, {loadUserServerMap, loadServerPlayers })(SocialMapComponent));