import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import { FlagIconCode } from 'react-flag-kit';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { connect } from 'react-redux';
import { ReduxState } from '../../reducers';
import { AlderonFriend, SocialGroup as SocialGroupType } from '../../api/social.types';
import { SocialFriends } from './SocialFriends';
import { ServerInfo } from '../../api/servers.types';
import { copyToClipboard } from '../../common/utils/utils';
import { withSmallMediaCheck, WithSmallMediaCheckProps } from '../../common/hooks/withSmallMediaCheck';
import CopyToClipboard from '../common/CopyToClipboard';
import { CountdownTimer } from '../common/CountDownTimer';
import { Flag } from '../common/Flag';
import { User } from '../../api';

interface ReduxStateProps {
  user?: User;
  loading: boolean;
}
interface ReduxActionProps {
  
}

interface ComponentProps {
  group: SocialGroupType
  servers: { [key: string]: ServerInfo }
  onPullServerPlayers: (serverKey: string) => void;
  onPlayerSelected: (player: AlderonFriend) => void;
}

interface State {
  expanded: boolean;
}

type Props = ComponentProps & ReduxStateProps & ReduxActionProps & WithSmallMediaCheckProps;

class SocialGroupComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      expanded: !!props.group,
    };
  }

  onExpandChanged = (event: React.SyntheticEvent, expanded: boolean) => {
    this.setState({ expanded });
  }

  onPullServerPlayers = () => {
    const { group } = this.props;
    const { server: serverReference } = group ?? {};

    if (this.props.onPullServerPlayers && serverReference) {
      this.props.onPullServerPlayers(serverReference.server.key);
    }
  }

  preventClick = (e: any) => {
    e.stopPropagation();
  }

  copyConnection = (e: any) => {
    e.stopPropagation();

    const { group , servers } = this.props;
    const {  server: serverReference } = group ?? {};
    const server = servers[serverReference?.server?.uuid as string];
    const { connection } = server ?? {};
    const address = `${connection?.ip_address}:${connection?.port}`.trim();
    if (address.length > 1) {
      copyToClipboard(`${connection?.ip_address}:${connection?.port}`);
    }
  }

  render = () => {
    const { group , servers } = this.props;
    const { friends, server: serverReference } = group ?? {};
    const server = servers[serverReference?.server?.uuid as string];
    const { map, players: serverPlayers, id, timestamps, connection, region, branch } = server ?? {};
    const { code, country } = (region ?? { code: 'US', country: 'United States' }) as { code: FlagIconCode, country: string };
    const address = `${connection?.ip_address}:${connection?.port}`.trim();
    const isOnline = !!serverReference;
    const isSmall = this.props.smallerThan.sm;
    const branchDisplay = isOnline && branch !== 'production' ? branch : '';

    let serverTitle = 'Offline';
    if (isOnline && server.flags?.official) {
      serverTitle = serverReference?.server?.slug ?? server.name;
    }
    if (isOnline && !server.flags?.official) {
      serverTitle = server.name;
    }
    if (isSmall) {
      serverTitle = serverTitle.replace('Server ', '')
    }

    const playerCount = serverPlayers?.count ?? 0;
    const players = friends;

    return (
      <Accordion expanded={this.state.expanded} onChange={this.onExpandChanged}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${id?.uuid ?? 'offline'}-content`}
          id={`${id?.uuid ?? 'offline'}-header`}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', marginRight: '1rem'}}>
                {!isSmall && 
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Typography variant="h6">{serverTitle}{map ? ` (${map})` : ''}</Typography>
                    {isOnline && <Flag sx={{ ml: '1rem' }} code={code} country={country} />}
                  </Box>
                }
                {isSmall && 
                <Box>
                  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Typography>{serverTitle}</Typography>
                    {isOnline && <Flag sx={{ ml: '1rem' }} code={code} country={country} />}
                  </Box>
                  <Typography sx={{ fontStyle: 'italic'}}>({map})</Typography>
                </Box>
                }
              {branchDisplay && <Typography sx={{ fontStyle: 'italic'}}>{branchDisplay}</Typography>}
              {isOnline &&
                <Box sx={{userSelect: 'text', cursor: 'text'}} onClick={this.preventClick}>
                  <Box sx={{ display: 'flex', flexDirection: isSmall ? 'column': 'row', justifyContent: 'space-between'}}>
                    <Box sx={{display: 'flex', flexDirection: 'row'}}><Typography sx={{ fontWeight: 'bold'}}>Players: </Typography><Typography sx={{ fontStyle: 'italic'}}>{playerCount}</Typography></Box>
                    <CountdownTimer title='Restarts' expiresAt={timestamps.expires_at} />
                  </Box>
                  <Box sx={{display: 'flex', flexDirection: 'row', userSelect: 'text', alignItems: 'center' }}>
                    <Typography sx={{ fontWeight: 'bold'}}>Connection</Typography>
                    <CopyToClipboard content={address} copiedMessage={'Connection copied to clipboard!'} />
                  </Box>
                </Box>
              }
            </Box>
        </AccordionSummary>
        <AccordionDetails>
          <SocialFriends friends={players} onPlayerSelected={this.props.onPlayerSelected} onPullServerPlayers={this.onPullServerPlayers} showPullServerPlayers={false} loading={this.props.loading} />
        </AccordionDetails>
      </Accordion>
    );
  };
}

const mapStateToProps = ({ auth, social }: ReduxState, { group }: ComponentProps) => {
  return {
    user: auth.user,
    loading: social.loading[group.server?.server.key ?? ''] ?? false,
  };
}

export const SocialGroup = withSmallMediaCheck(connect(mapStateToProps, { })(SocialGroupComponent as any));